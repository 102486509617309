import { Injectable } from '@angular/core';
import { Helper } from '../shared/helper';

@Injectable({ providedIn: 'root' })
export class CommonService {

  private _image_settings;
  private _settings;
  
  constructor(private helper: Helper) {}


  get image_settings(){
    return this._image_settings;
  }


  get settings(){
    return this._settings;
  }
  

  store_image_settings():Promise<boolean> {
    return new Promise((resolve,rejects)=>{
      this.helper.http_post_method_requester(this.helper.POST_METHOD.GET_IMAGE_SETTING, {}).then((res_data)=>{
        if(res_data.success){this._image_settings  = res_data.image_setting}
        resolve(true)
      })
    })
  }

  getCountryList(){
    return this.helper.http_get_method_requester(this.helper.GET_METHOD.GET_COUNTRY_LIST, {})
  }
  
  getCityList(country_id){
    return this.helper.http_post_method_requester(this.helper.POST_METHOD.GET_CITY_LIST, {country_id})
  }

  getDeliveryList(city_id){
    return this.helper.http_post_method_requester(this.helper.POST_METHOD.GET_DELIVERY_LIST_FOR_CITY, {city_id})
  }

  verify_captcha(json){
    return this.helper.http_post_method_requester(this.helper.POST_METHOD.VARIFY_CAPTCHA, json)
  }
  
  check_referral(json){
    return this.helper.http_post_method_requester(this.helper.POST_METHOD.CHECK_REFERRAL, json).then(res=>{
      return res;
    })
  }

  store_setting_details(){
    return new Promise((resolve,rejects)=>{
      this.helper.http_post_method_requester(this.helper.POST_METHOD.GET_SETTING_DETAIL, {}).then((res_data)=>{
        this._settings = res_data.setting;

       // return this._settings;
        resolve(this._settings);

    

      })
    })
  }

  otp_verification(json){
    return this.helper.http_post_method_requester(this.helper.POST_METHOD.OTP_VERIFICATION, json)
  }
  
  store_verification(json){
    return this.helper.http_post_method_requester(this.helper.POST_METHOD.STORE_GENERATE_OTP, json)
  }

  // resend_otp_verification(json){
  //   return this.helper.http_post_method_requester(this.helper.POST_METHOD.RESEND_OTP_VERIFICATION, json)
  // }

  

}
